<template>
  <full-calendar :options="config" />
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { ISOToCustomFormat } from "@/libs/helpers";

export default {
  props: {
    events: {
      type: Array
    }
  },
  components: {
    FullCalendar,
  },
  data() {
    return {
      key: new Date(),
      config: {
        events: [],
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        themeSystem: 'bootstrap5',
        initialView: "dayGridMonth",
        locale: "es",
        eventDisplay: 'color',
        eventTimeFormat: {
          hour: 'numeric',
          minute: '2-digit',
          meridiem: false,
        },
        buttonText: {
          today: this.$t('Today'),
          month: this.$t('Month'),
          week: this.$t('Week'),
          day: this.$t('Day'),
        },
        datesSet: this.datesSet,
        eventClick: this.eventClick
      },
    };
  },
  watch: {
    events(value) {
      this.config.events = value 
    }
  },
  methods: {
    datesSet(event) {
      this.$emit('datesSet', {
        start: ISOToCustomFormat(event.startStr),
        end: ISOToCustomFormat(event.endStr)
      })
    },
    eventClick(event) {
      this.$emit('eventClick', event.event)
    }
  },
};
</script>

<style>

.fc-button-active {
  opacity: .65 !important;
}

.fc-button {
  padding: 0.2rem 1.5rem !important;
  border-radius: 0.358rem !important;
  font-size: 1rem;
  margin-right: 5px !important;
}

.fc-button:focus {
  outline: #ffff solid 4px !important;
}

.fc-button-primary {
  background-color: var(--primary) !important;
  border-color: inherit !important
}

</style>