<template>
  <b-row>
     <b-col sm="3">
      <b-card
        class="bg-info-fade height-card"
      >
        <h4 class="mb-1 d-inline-block" ><strong class="mr-1" >{{ $t('Tareas') }}</strong></h4>
        <b-badge variant="info">{{ $t('Total') }}</b-badge>
        <h2 class="mb-1" ><strong>{{ this.totalItems }}</strong></h2>
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-card
        class="bg-danger-fade height-card"
      >
        <h4 class="mb-1 d-inline-block" ><strong class="mr-1" >{{ $t('Tareas') }}</strong></h4>
        <b-badge variant="danger">{{ $t('Pendiente') }}</b-badge>
        <h2 class="mb-1" ><strong>{{ this.pendingItems }}</strong></h2>
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-card
        class="bg-warning-fade height-card"
      >
        <h4 class="mb-1 d-inline-block" ><strong class="mr-1" >{{ $t('Tareas') }}</strong></h4>
        <b-badge variant="warning">{{ $t('En progreso') }}</b-badge>
        <h2 class="mb-1" ><strong>{{ this.progressItems }}</strong></h2>
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-card
        class="bg-success-fade height-card"
      >
        <h4 class="mb-1 d-inline-block" ><strong class="mr-1" >{{ $t('Tareas') }}</strong></h4>
        <b-badge variant="success">{{ $t('Completado') }}</b-badge>
        <h2 class="mb-1" ><strong>{{ this.completedItems }}</strong></h2>
      </b-card>
    </b-col>
   
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BBadge,
  },
  props: {
    totalItems: 0,
    pendingItems: 0,
    progressItems: 0,
    completedItems: 0,
  },
  data() {
    return {
      type: 'line',
    }
  },
}
</script>
<style lang="scss" >
</style>
