<template>
  <div>
    <StadisticsTasks
      :total-items="totalItems"
      :pending-items="dataToDo"
      :progress-items="dataInProgress"
      :completed-items="dataComplete"
    />
    <b-card no-body class="mb-1">
      <div class="d-flex justify-content-between align-items-center px-1 py-1">
        <div>
          <h5 class="m-0 font-weight-bolder">
            {{ $t("Tareas") }}
          </h5>
        </div>
        <div class="d-flex align-items-center">
          <div>
            <b-link :to="{ name: 'createTask' }">
              <b-button variant="primary" class="mr-1 text-nowrap">
                {{ $t("Crear nueva") }}
              </b-button>
            </b-link>
          </div>
          <b-nav-item-dropdown
            right
            toggle-class="d-flex align-items-center dropdown-user-link"
            class="dropdown-user"
          >
            <template #button-content>
              <feather-icon size="16" icon="MoreHorizontalIcon" />
            </template>

            <b-dropdown-item
              :disabled="items.length === 0"
              @click="download()"
              link-class="d-flex align-items-center"
            >
              <feather-icon size="16" icon="DownloadIcon" class="mr-50" />
              <span> {{ $t("Descargar lista Excel") }}</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <span
            class="ml-1 cursor-pointer"
            @click="visibleFilter = !visibleFilter"
          >
            <feather-icon icon="FilterIcon" size="20" />
          </span>
          <b-button-group size="sm" class="ml-1">
            <b-button 
              @click="activeCalendarMode(false)" 
              :variant="calendarMode ? 'outline-primary' : 'primary'" 
              squared
            >
              <feather-icon icon="ListIcon" size="20" />
            </b-button>
            <b-button 
              @click="activeCalendarMode(true)" 
              :variant="calendarMode ? 'primary' : 'outline-primary'" 
              squared
            >
              <feather-icon icon="CalendarIcon" size="20" />
            </b-button>
          </b-button-group>
        </div>
      </div>
      <!-- FILTERS -->
      <div>
        <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
          <div class="px-2">
            <b-row>
              <b-col sm="4">
                <b-form-group
                  :label="$t('FechaInicio')"
                  label-for="filter-fechaini"
                >
                  <flat-pickr
                    v-model="date_ini"
                    @input="chargeData"
                    name="fecha_ini"
                    class="form-control"
                    :disabled=calendarMode
                  />
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group
                  :label="$t('FechaFin')"
                  label-for="filter-fechafin"
                >
                  <flat-pickr
                    v-model="date_fin"
                    @input="chargeData"
                    name="fecha_ini"
                    class="form-control"
                    :disabled=calendarMode
                  />
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group :label="$t('Nombre')" label-for="filter-search">
                  <b-row>
                    <b-col sm="8">
                      <b-form-input
                        v-model="searchTerm"
                        :placeholder="$t('Busqueda')"
                        type="text"
                        class="d-inline-block"
                      />
                    </b-col>
                    <b-col sm="4" style="padding-left: 0px">
                      <b-button variant="primary" @click="handleSearch">
                        {{ $t("Buscar") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group :label="$t('Orden')" label-for="filter-order">
                  <v-select
                    v-model="orderby"
                    label="label"
                    :filterable="false"
                    :searchable="false"
                    :options="selectOrderBy"
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.label }}
                      <feather-icon
                        v-if="option.type == 'asc'"
                        icon="ArrowUpIcon"
                        size="16"
                      />
                      <feather-icon
                        v-if="option.type == 'desc'"
                        icon="ArrowDownIcon"
                        size="16"
                      />
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.label }}
                      <feather-icon
                        v-if="option.type == 'asc'"
                        icon="ArrowUpIcon"
                        size="16"
                      />
                      <feather-icon
                        v-if="option.type == 'desc'"
                        icon="ArrowDownIcon"
                        size="16"
                      />
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group :label="$t('Proridad')" label-for="filter-order">
                  <v-select
                    v-model="priority"
                    :filterable="false"
                    :searchable="false"
                    :options="selectPriorities"
                  >
                    <template slot="option" slot-scope="option">
                      {{ $t(`priority.${option.label}`) }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ $t("priority." + option.label) }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group :label="$t('Estado')" label-for="filter-order">
                  <v-select
                    v-model="status"
                    @input="chargeData"
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="selectStatus"
                    multiple
                  >
                    <template slot="option" slot-scope="option">
                      {{ $t("status.tasks." + option.alias) }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ $t("status.tasks." + option.alias) }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <!-- <b-col sm="4">
                <b-form-group
                  :label="$t('Evento')"
                  label-for="filter-order"
                >
                  <v-select
                    v-model="type"
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="events"
                    :placeholder="$t('Evento')"
                  >
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      {{ option.name }}
                    </template>
                    <template
                      slot="selected-option"
                      slot-scope="option"
                    >
                      {{ option.name }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col> -->
              <b-col sm="4">
                <b-form-group
                  :label="$t('Usuarios')"
                  label-for="filter-order"
                >
                  <BaseSelect
                    v-model="responsable"
                    :searchable="true"
                    :clearable="true"
                    :httpBody="{
                      id_company: currentCompany.id,
                      id_client: currentClient.id,
                    }"
                    :resource="'/users'"
                    :disabled="user != null"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-collapse>
      </div>
      <!-- ITEMS VIEW -->
      <b-table v-if="items && !calendarMode" hover responsive :items="items" :fields="columns">
        <template #cell(event)="data">
          <span v-if="data.item.event !== null">
            <!--  {{ $t("typeevents." + data.item.event.type) }} -->
            <b-badge
              variant="ligth"
              pill
              style="
                border: 1px solid lightgray;
                color: black;
                font-weight: 500;
              "
            >
              {{ $t(`typeevents.${data.item.event.alias}`) }}
            </b-badge>
          </span>
        </template>
        <template #cell(responsible_user)="data">
          <div class="d-flex align-middle">
            <b-link
              v-if="data.item.users.length > 0"
              :to="{
                name: 'viewUser',
                params: { id: data.item.users[0].id },
              }"
            >
              {{ data.item.users[0].name }}
              {{ data.item.users[0].surname }}
            </b-link>
            <div v-show="data.item.users.length > 1" :id="`users-${data.item.id}`">
              <feather-icon class="text-primary ml-1" icon="PlusCircleIcon" size="19" />
            </div>
            <b-tooltip
              triggers="hover"
              :target="`users-${data.item.id}`"
              variant="primary"
              placement="right"
            >
              <ul style="padding-left: 5px;" class="mx-1 mb-0">
                <li v-for="user in data.item.users.slice(1)" :key=user.id class="text-left">
                  {{ user.name }} {{ user.surname }}
                </li>
              </ul>
            </b-tooltip>
          </div>
        </template>
        <template #cell(date_ini)="data">
          {{ dateColumn(data.item.date_ini) }}
        </template>
        <template #cell(date_fin)="data">
          {{ dateColumn(data.item.date_fin) }}
        </template>
        <template #cell(priority)="data">
          {{ $t("priority." + data.item.priority) }}
        </template>
        <template #cell(status)="data">
          <b-badge :variant="statusTasks[data.item.status.alias]">
            {{ $t(`status.tasks.${data.item.status.alias}`) }}
          </b-badge>
        </template>
        <template #cell(frequency_type)="data">
          <span>{{
            data.item.frequency_type
              ? $t(`review_frequency.${data.item.frequency_type}`)
              : "-"
          }}</span>
        </template>
        <template #cell(actions)="data">
          <span>
            <b-link
              :to="{ name: 'viewTask', params: { id: data.item.id } }"
              class="mr-1"
            >
              <feather-icon icon="EyeIcon" size="16" />
            </b-link>
            <b-link
              v-if="!['closed', 'complete'].includes(data.item.status.alias)"
              :to="{ name: 'editTask', params: { id: data.item.id } }"
              class="mr-1"
            >
              <feather-icon icon="Edit2Icon" size="16" />
            </b-link>
            <span
              class="text-danger cursor-pointer"
              @click="deleteTask(data.item.id, data.item.name)"
            >
              <feather-icon icon="TrashIcon" size="16" />
            </span>
          </span>
        </template>
      </b-table>
      <TasksSchedule 
        v-if="calendarMode" 
        class="m-1" 
        @datesSet="handleDatesSetCalendar" 
        @eventClick="goToEvent"
        :events="eventsCalendar" 
      />
    </b-card>
    <!-- PAGINATION -->
    <div v-if="!calendarMode" class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center mb-0">
        <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
        <b-form-select
          v-model="pageLength"
          :options="pages"
          class="ml-50 mr-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> {{ $t("Total") }}: {{ totalItems }}</span>
      </div>
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="pageLength"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import store from "@/store/index";
import flatPickr from "vue-flatpickr-component";
import { FlatPickrToTimestamp, TimestampToFlatPickr, TimestampToCustomFormat } from "@/libs/helpers";
import vSelect from "vue-select";
import { config } from "@/shared/app.config";
import StadisticsTasks from "@/views/tasks/Stadistics";
import axios from "axios";
import DownloadService from "@/shared/services/download-service";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import TasksSchedule from "@/components/tasks/calendar/TasksSchedule.vue"

export default {
  components: {
    flatPickr,
    vSelect,
    StadisticsTasks,
    BaseSelect,
    TasksSchedule
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      log: [],
      statusTasks: config.statusTasksVariants,
      calendarMode: false,
      visibleFilter: false,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      responsable: "",
      pages: ["10", "15", "25"],
      orderby: {
        label: this.$t("FechaInicio"),
        column: "date_ini",
        type: "desc",
      },
      selectOrderBy: [
        {
          label: this.$t("FechaInicio"),
          column: "date_ini",
          type: "desc",
        },
        {
          label: this.$t("FechaInicio"),
          column: "date_ini",
          type: "asc",
        },
        {
          label: this.$t("FechaFin"),
          column: "date_fin",
          type: "desc",
        },
        {
          label: this.$t("FechaFin"),
          column: "date_fin",
          type: "asc",
        },
      ],
      date_ini: "",
      date_fin: "",
      priority: "",
      status: "",
      selectPriorities: config.priorities,
      columns: [
        {
          label: this.$t("Inicio"),
          key: "date_ini",
        },
        {
          label: this.$t("Fin"),
          key: "date_fin",
        },
        {
          label: this.$t("Nombre"),
          key: "name",
        },
        {
          label: this.$t("ActionType"),
          key: "event",
        },
        {
          label: this.$t("Estado"),
          key: "status",
        },
        {
          label: this.$t("Usuarios"),
          key: "responsible_user",
        },
        {
          label: this.$t("Frequency"),
          key: "frequency_type",
        },
        {
          label: this.$t("Acciones"),
          key: "actions",
          class: "text-right",
          thStyle: { minWidth: "10rem" },
        },
      ],
      searchTerm: "",
      type: "",
    };
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      currentCompany: "companies/getCurrentCompany",
      items: "tasks/getItems",
      totalItems: "tasks/getTotalItems",
      selectStatus: "tasks/getSelectStatus",
      dashboard: "actions/getDashboardData",
      events: "events/getItems",
    }),
    dataToDo() {
      if (this.dashboard.tasks) {
        return this.dashboard.tasks.to_do;
      }
      return "";
    },
    dataInProgress() {
      if (this.dashboard.tasks) {
        return this.dashboard.tasks.in_progress;
      }
      return "";
    },
    dataComplete() {
      if (this.dashboard.tasks) {
        return this.dashboard.tasks.complete;
      }
      return "";
    },
    dataUsersActive() {
      if (this.dashboard.users) {
        return this.dashboard.users.active;
      }
      return 0;
    },
    dataUsersTotals() {
      if (this.dashboard.users) {
        return this.dashboard.users.totals;
      }
      return 0;
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    eventsCalendar () {
      let items = []

      if(this.calendarMode) {
        items = this.items.map((item) => {
          const startDateFormat = TimestampToCustomFormat(item.date_ini)
  
          return {
            id:  item.id,
            title: `${item.name} - ${this.$t(`status.tasks.${item.status.alias}`)}`, 
            start: startDateFormat,
            end: item.date_fin !== 'null' ? 
              TimestampToCustomFormat(item.date_fin) :
              startDateFormat,
            eventDisplay: 'none',
            color: config.statusTasksVariantsHex[item.status.alias], 
            textColor: 'white',
          }
        })
      }
      return items
    }
  },
  watch: {
    orderby() {
      this.chargeData();
    },
    priority() {
      this.chargeData();
    },
    responsable() {
      this.chargeData();
    },
    type() {
      this.chargeData();
    },
  },
  methods: {
    ...mapActions({
      list: "tasks/getListTasks",
      delete: "tasks/delete",
      getSelectStatus: "tasks/getSelectStatus",
      getDashboard: "actions/getDashboardData",
      getEvents: "events/getList",
    }),
    ...mapMutations({
      loading: "app/loading",
    }),
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
    dateColumn(time) {
      if (time !== "null") {
        return TimestampToFlatPickr(time);
      }
      return "";
    },
    deleteTask(id, name) {
      this.$bvModal
        .msgBoxConfirm(this.$t("EliminarConfirm", { nombre: name }), {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: this.$t("Eliminar"),
          cancelTitle: this.$t("Cancelar"),
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.delete(id);
          }
        });
    },
    async chargeData() {
      let filterDateIni = "";
      let filterDateFin = "";
      if (this.date_ini) {
        filterDateIni = FlatPickrToTimestamp(this.date_ini);
      }
      if (this.date_fin) {
        filterDateFin = FlatPickrToTimestamp(this.date_fin);
      }
      let priority = "";
      if (this.priority && this.priority !== null) {
        priority = this.priority;
      }
      const statusSelected = [];
      if (this.status.length > 0) {
        this.status.forEach((element) => {
          statusSelected.push(element.id);
        });
      }
      const client =
        this.currentClient && this.currentClient !== null
          ? this.currentClient.id
          : "";
      await this.getDashboard({
        client,
        user_id: this.user ? this.user.id : null
      });

      const responsable = this.responsable ? this.responsable.id : "";

      this.list({
        client,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        date_ini: filterDateIni,
        date_fin: filterDateFin,
        priority,
        status: statusSelected,
        orderBy: this.orderby,
        user: responsable,
        event_id: this.type.id,
      });
    },
    async download() {
      let cliente = this.currentClient ? this.currentClient.id : "";
      const responsable = this.responsable ? this.responsable.id : "";

      if (this.role !== "admin_cliente" && cliente === "") {
        cliente = this.client ? this.client.id : "";
      }
      let filterDateIni = "";
      let filterDateFin = "";
      if (this.date_ini) {
        filterDateIni = FlatPickrToTimestamp(this.date_ini);
      }
      if (this.date_fin) {
        filterDateFin = FlatPickrToTimestamp(this.date_fin);
      }
      let priority = "";
      if (this.priority) {
        priority = this.priority;
      }
      const statusSelected = [];
      if (this.status.length > 0) {
        this.status.forEach((element) => {
          statusSelected.push(element.id);
        });
      }

      this.loading(true);
      try {
        const response = await axios({
          url: `${config.webApiBase}/tasks/list/download`,
          data: {
            client_id: cliente,
            search: this.searchTerm,
            date_ini: filterDateIni,
            date_fin: filterDateFin,
            priority,
            status: statusSelected,
            orderBy: this.orderby,
            user: responsable,
            event_id: this.type.id,
            lang: this.getCurrentLanguage,
          },
          method: "POST",
          responseType: "blob",
        });
        DownloadService.resolveAndDownloadBlob(response.data, "Tareas", "xlsx");
      } catch (error) {
        this.$toast.error(
          "Error en la descarga del listado. Por favor inténtelo de nuevo mas tarde."
        );
      }
      this.loading(false);
    },
    activeCalendarMode(value) {
      if (value) {
        this.status = this.selectStatus.filter((status) => status.alias === 'to_do' || status.alias === 'inbox')
      } else {
        this.date_fin = ''
        this.date_ini = ''
        this.status = []
      }
      this.calendarMode = value
    },
    handleDatesSetCalendar(range) {
      this.date_ini = range.start
      this.date_fin = range.end
    },
    goToEvent(event) {
      this.$router.push({ name: 'viewTask', params: { id: event.id } })
    }
  },
  async created() {
    if (this.user) {
      this.responsable = this.user
    }
    await this.chargeData();
    await this.getSelectStatus();
    await this.getEvents();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.dropdown-user {
  list-style-type: none;
}
[dir] .b-nav-dropdown .dropdown-toggle::after {
  display: none;
}
</style>
