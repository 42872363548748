<template>
  <div>
    <b-row>
      <b-col cols="12">
        <ListTable />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import ListTable from '@/views/tasks/ListTable'

export default {
  components: {
    BRow,
    BCol,
    ListTable,
  },
}
</script>
